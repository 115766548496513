.header{
    width:100%;
    height:100vh;
    align-items: center;
    display:flex;
    justify-content: center;
}
.card{
    border:2px white;
    box-shadow: 0 20px 25px -5px rgb(0 0 0 / 0.1);
    border-radius: 30px;
    background-color: white;
    align-items: center;
    display: flex;
    min-width: 500px;
    flex-direction: column;
    padding:60px 40px;

}
.input-username{
    width: 100%;
    padding-top: 50px;
}
.input-password{
    padding-top: 20px;
    width: 100%;
}
.remember-group{
    padding-top: 20px;
    display: flex;
    width: 100%;
}
.rememberMe-div{
    display: flex;
    flex-direction: column;
}
.remember-span{
    padding-top: 10px;
    font-size: 14px;
}
.forgot-span{
    padding-top: 10px;
    font-size: 14px;
    font-weight: bold;
}
.forgot-div{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: end;
    color: #3bafda;
}
.button-div{
    padding-top: 20px;
    width: 100%;
}
.login-button{
    width:100%
}