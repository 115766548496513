.selected-user-picture{
    border-radius: 50%;
}
.profile-span{
    font-weight: bold;

}
.profile-info-wrapper{
    display: flex;
    flex-direction: column;
    gap:20px;
}