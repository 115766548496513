.flow-viewer {
    background: #fff;
}

.flow-container {
    width: 100%;
    height: 800px;
}

path.react-flow__edge-path {
    stroke-width: 5;
    stroke: rgb(17, 130, 242);
}

path.react-flow__edge-path:hover {
    stroke-width: 10;
}

.react-flow__handle {
    min-width: 12px !important;
    min-height: 12px !important;
}

.flow-viewer-title {
    font-size: 24px;
    font-weight: bold;
    margin-right: 20px;
    display: flex;

}

.react-flow__handle-right {
    right: -10px !important;
    width: 16px !important;
    height: 16px !important;
    background: rgb(16, 216, 26) !important;
}

.react-flow__handle-left {
    left: -10px !important;
    width: 16px !important;
    height: 16px !important;
    background: rgb(255, 143, 23) !important;
}

.node-drag-button:hover {
    animation: shake 0.8s 1.5s 1;
}

@keyframes shake {
    0% {
        transform: translate(1px, 1px) rotate(0deg);
        box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;

    }

    10% {
        transform: translate(-1px, -2px) rotate(-1deg);
    }

    20% {
        transform: translate(-3px, 0px) rotate(1deg);
    }

    30% {
        transform: translate(3px, 2px) rotate(0deg);
    }

    40% {
        transform: translate(1px, -1px) rotate(1deg);
    }

    50% {
        transform: translate(-1px, 2px) rotate(-1deg);
    }

    60% {
        transform: translate(-3px, 1px) rotate(0deg);
    }

    70% {
        transform: translate(3px, 1px) rotate(-1deg);
    }

    80% {
        transform: translate(-1px, -1px) rotate(1deg);
    }

    90% {
        transform: translate(1px, 2px) rotate(0deg);
    }

    100% {
        transform: translate(1px, -2px) rotate(-1deg);
        box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;

    }
}

.flow-viewer-preloader {
    width: 100vw;
    height: 100vh;
    background: #00000070;
    position: absolute;
    z-index: 100;
    left: 0px;
    right: 0px;
    top: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    pointer-events: none;
}

.flow-viewer-updating {
    width: 100vw;
    height: 100vh;
    position: absolute;
    z-index: 120;
    left: 0px;
    right: 0px;
    top: 0px;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    pointer-events: none;
    overflow: hidden;
}


.updating-text {
    background: rgb(49, 148, 214, 0.6);
    padding: 5px 20px;
    margin-bottom: 10px;
    margin-right: 10px;
    border-radius: 10px;
    font-weight: bold;
    color: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    animation: slide-from-bottom-viewer 0.15s;
}

@keyframes slide-from-bottom-viewer {
    0% {
        transform: translateY(100px);
    }

    100% {
        transform: translateY(0px);
    }
}