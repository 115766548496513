.datatable-crud-demo .table-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
@media screen and (max-width: 960px) {
    .datatable-crud-demo .table-header {
        align-items: flex-start;
    }
}
.datatable-crud-demo .product-image {
    width: 100px;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}
.datatable-crud-demo .p-dialog .product-image {
    width: 150px;
    margin: 0 auto 2rem auto;
    display: block;
}
.datatable-crud-demo .confirmation-content {
    display: flex;
    align-items: center;
    justify-content: center;
}
@media screen and (max-width: 960px) {
    .datatable-crud-demo .p-toolbar {
        flex-wrap: wrap;

    }
    .datatable-crud-demo .p-toolbar .p-button {
        margin-bottom: 0.25rem;
    }
    .datatable-crud-demo .table-header {
        flex-direction: column;

    }
    .datatable-crud-demo .table-header .p-input-icon-left, .datatable-crud-demo .table-header input {
        width: 100%;
    }
}
.filter-div{
    width: 100%;
    display: flex;
    flex-direction: column;
}
.input-div{
    width: 100%;
    display: flex;
    gap:20px;
    padding: 30px 0px;
    justify-content: space-evenly;
}
.dropdown{
    width: 40%;
}
.bring-list-div{
    display: flex;
    width: 100%;
    justify-content: center;
}
.clear-button{
    display: flex;
    margin-left: 10px;
    align-items: center;
    cursor: pointer;
    color: #1d4ed8;

}
.clear-button:hover {
    text-decoration: underline;
}
.dotIcon{
    color: white !important;
}
.chv-body-test{
    display: flex;
    background-color: white;
    border: 1px solid #dcdcdc;
    color: black;
    box-sizing: border-box;
    padding:20px;
    border-radius: 10px;
    max-width: 500px;
}
.options-wrapper-test {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 20px;
    padding: 10px 20px;
    justify-content: space-around;
    width: 100%;
}
.option-test {
    display: flex;
    justify-content: center;
    -webkit-animation: slide-in .3s ease-in-out 1 both;
    animation: slide-in .3s ease-in-out 1 both;
    background: #026BFD;
    border: 1px solid #dcdcdc50;
    border-radius: 12px;
    color: white;
    cursor: pointer;
    font-weight: 700;
    min-width: 120px;
    opacity: .8!important;
    padding: 10px;
    text-align: center;
    transition: -webkit-transform .2s;
    transition: transform .2s;
    transition: transform .2s,-webkit-transform .2s;
}
.last-option-test{
    display: flex;
    justify-content: center;
    -webkit-animation: slide-in .3s ease-in-out 1 both;
    animation: slide-in .3s ease-in-out 1 both;
    background: #026BFD;
    border: 1px solid #dcdcdc50;
    border-radius: 12px;
    color: white;
    cursor: pointer;
    font-weight: 700;
    min-width: 120px;
    opacity: .8!important;
    padding: 10px;
    text-align: center;
    transition: -webkit-transform .2s;
    transition: transform .2s;
    transition: transform .2s,-webkit-transform .2s;
}
.typing {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding-left: 10px;
    margin-bottom: 20px;
    padding-bottom: 20px;
    text-transform: capitalize;
}

.typing .typing-circle {
    width: 8px;
    height: 8px;
    background: purple;
    margin-left: 2px;
    margin-right: 2px;
    border-radius: 50%;
    animation: typing-animation 0.3s ease infinite alternate;
}

.typing .typing-text {
    margin-left: 10px;
}

.typing-circle:nth-child(1) {
    animation-delay: 50ms;
}

.typing-circle:nth-child(2) {
    animation-delay: 100ms;

}

.typing-circle:nth-child(3) {
    animation-delay: 150ms;
}

@keyframes typing-animation {
    0% {
        transform: scale(0.5);
    }

    100% {
        transform: scale(1);
    }
}