.header{
    width: 100%;
    height: 100vh;
}
.log-bring-list-div{
    display: flex;
    width: 100%;
    justify-content: center;
    margin-top: 30px;
}
