.analytic-page {
    width: 100%;
    display: grid;
    grid-template-columns: 20% 20% 20% 20% 20%;
    gap: 15px;
    padding: 50px;
    justify-content: center;
}

body {
    overflow-x: hidden;
}

.grid {
    display: flex;
    flex-wrap: wrap;
    margin-right: -0.5rem;
    margin-left: -0.5rem;
    margin-top: -0.5rem;
}

.col {
    flex-grow: 1;
    flex-basis: 0;
}