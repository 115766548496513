.nav-head {
    display: flex;
    height: 100vh;
    width: 6rem;
    background-color: #1d2b36;
    position: fixed;
    flex-direction: column;
    align-items: center;
    padding-top: 1rem;
    color: white;
    transition: width 0.2s;
}

.nav-head:hover {
    width: 14.5rem;
}

.nav-head span {
    display: block;
    font-weight: 1000;
    margin-left: 0;
    max-width: 0;
    overflow: hidden;
    transition: all 0.2s;
    white-space: nowrap;
}

.nav-head:hover span {
    display: inline;
    max-width: 200px;
    margin-left: 10px;

}

.nav-head i {
    font-size: 24px;
}

.content {
    overflow-y: auto;
    margin-top: 1rem;
    display: flex;
    flex-direction: column;
    width: 100%;
    padding-right: 15px;
    padding-left: 10px;

    padding-bottom:100px;

}
/* ===== Scrollbar CSS ===== */
/* Firefox */
* {
    scrollbar-width: auto;
    scrollbar-color: #5c4490 #ffffff;
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
    width: 10px;
}

*::-webkit-scrollbar-track {
    background: #1d2b36;
}

*::-webkit-scrollbar-thumb {
    background-color: #5c4490;
    border-radius: 10px;
}

.content:hover {
    cursor: pointer;
}

.content-item {
    margin-bottom: 0.5rem;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    box-sizing: border-box;
    padding: 10px 10px;
    border-radius: 8px;
    font-weight: bold;
}

.content-item-sub {
    margin-bottom: 0.1rem;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    box-sizing: border-box;
    padding: 10px 10px;
    border-radius: 8px;
    font-weight: bold;
    opacity: 0.6;
}

.content-item:hover {
    background: #5b448f50;

}

.content-item.selected {
    background: #5b448f;
}

.content-item-sub.selected {
    background: #5b448f;
}

.side-bar-icon {
    width: 25px;
    height: 25px;
}

.cbu-panel {
    display: flex;
    flex-direction: column;
    margin-top: 30px;
    align-items: center;
}

.sidebar-button {
    margin-top: 20px !important;
}
.menu-logo-img{
    border-radius: 10px;
}