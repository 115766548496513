.user-validated{
    border-radius: 50%;
    width: 25px;
    height: 25px;
    box-sizing: border-box;
    display: block;
}
.validation-header{
    display: flex;
    justify-content: end;
    gap:10px;
}
.validation-que-header{
    display: flex;
    gap:10px;
}
.validation-dialog-buttons{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    margin-top: 40px;
}
.validation-dropdown{
    width: 80%;
}
.validation-info{
    gap:10px;
    display: flex;
    flex-direction: column;
}
.validation-wrapper{
    height:210px;
    gap:10px;
    display: flex;
    border: 1px solid #dee2e6;
    background-color: #f8f9fa;
    border-radius: 10px;
    padding: 20px;
    justify-content: space-between;
    align-items: center;
}
.validation-filter-div{
    width: 70%;
    display: flex;
    flex-direction: column;
}
