.overview-wrapper{
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.overview-card {
    width: 100%;
    margin-top: 50px;
}
.overview-body{
    gap:20px;
    display: flex;
    justify-content: center;
    align-items:center;
    flex-direction: column;
}
.overview-title{
    text-align: center;
    font-family: "Montserrat", sans-serif;
}
.overview-button-div{
    display: flex;
    justify-content: space-between;
    gap:20px;
    width: 100%;
    margin-top: 100px;
}
.overview-div{
    height:80vh;
    display: flex;
    width: 100%;
    flex-direction: column;

}
.flow-question-text{
    height: 44px !important;
}
.flow-question-text-wrapper{
    display: flex;
    align-items: center;
    justify-content: center;
    gap:5px
}
.overview-span{
    font-weight: 600;
}
.spans-wrapper{
    display: flex;
    margin-top: 100px;
    gap:50px
}
.spans-div{
    display: flex;
    align-items: center;
    gap:10px;
    font-size: 18px;
    margin-top:10px;
}
.overview-span-initial{
    font-weight: 600;
    font-size: 20px;
}
.step-wrapper {
    border: 2px solid #ccc;
    border-radius: 10px;
    padding: 30px;
    margin-bottom: 20px;
}

