.initial-sentence-wrapper{
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.initial-sentence-card {
    width: 100%;
    margin-top: 120px;
}
.initial-sentence-body{
    gap:20px;
    display: flex;
    justify-content: center;
    align-items:center;
    flex-direction: column;
}
.initial-sentence-title{
    text-align: center;

    font-family: "Montserrat", sans-serif;
}
.initial-sentence-button-div{
    display: flex;
    justify-content: space-between;
    gap:20px;
    width: 100%;
    margin-top: 100px;
}
.overview-div{
    height:80vh;
    display: flex;
    width: 100%;
    flex-direction: column;

}
.initial-sentence-text{
    height: 44px !important;
}
.initial-sentence-text-wrapper{
    display: flex;
    align-items: center;
    justify-content: center;
    gap:5px;
    margin-top: 50px;
}
