.response-dialog{
    display: flex;
    flex-direction: column;
    gap: 10px;
}
.response-header{
    font-weight: bold;
}
.response-message{
    font-weight: normal;
}