.label-selection-div{
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.label-selection-card {
    width: 100%;
    margin-top: 50px;
}
.label-selection-body{
    gap:20px;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
}
.label-selection-title{
    text-align: center;
    font-family: "Montserrat", sans-serif;
}
.label-selection-button-div{
    display: flex;
    justify-content: space-between;
    gap:20px;
    width: 100%;
    margin-top:100px
}
.label-selection-div{
    height:80vh;
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: space-between;
}
.flow-question-text{
    height: 44px !important;
}
.flow-question-text-wrapper{
    display: flex;
    align-items: center;
    justify-content: center;
    gap:5px
}