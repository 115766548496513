.nutrition-method-card {
    width: 100%;
}
.nutrition-method-body{
    gap:20px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
}
.nutrition-method-title{
    text-align: center;
    font-family: "Montserrat", sans-serif;
}
.nutrition-method-button-div{
    display: flex;
    justify-content: end;
    margin-top: 100px;
    gap:20px;
    width: 100%;
}

.nutrition-method-div{
    margin-top: 50px;
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.nutrition-dropdown{
    width: 100%;
}
.dropdown-wrapper{
    display: flex;
    align-items: center;
    justify-content: center;
    width:35%
}
.dropdown-wrapper .p-button-rounded:hover{
    background-color: transparent !important;
}
.dropdown-wrapper .p-button-rounded{
    box-shadow: none !important;
}
.p-tooltip{
    max-width: 400px !important;
}
.combobox-wrapper{
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    gap:40px;
    margin-top:60px

}