
.chart-card  {
    margin: 50px;
}

.col2 {
    width: 100%;
    display: grid;
    grid-template-columns: 50% 50%;
    gap: 15px;
    justify-content: center;
}