.active-session {
    background-color: green;
    color: white;
    display: flex;
    justify-content: center;
    width: 50%;
    border-radius: 7px;
    padding: 4px 0px;
}
.training-information-div{
    margin:10px 0 10px 0;
    width: 100%;
    background-color: rgba(255, 159, 64, 1);
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    font-weight: bold;
}
.newjob-wrapper{
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    margin-top: 50px;
}
.training-error-div{
    display: flex;
    background-color: #E86B60!important;
    color: white;
    width: 100%;
    height: 60px;
    font-weight: bold;
    justify-content: center;
    align-items: center;
}
.activeButton{
    visibility: hidden;
    font-size: 13px;
    height: 30px;
    padding:3px 10px 3px 10px;
    margin: 0px !important;
    opacity: 1 !important;
}
.dataTable tr:hover .activeButton{
    visibility: visible;
}
.training-datatable-header{
    display: flex;
    justify-content: flex-end;
}
.dataset_status.dataset_status_dev{
    display: block;
    padding:5px;
    background-color: #6076d2;
    color: #ffffff;
    margin: 5px;
    border-radius:10px;
    font-size:0.7rem;
    text-align: center;
}
.dataset_status.dataset_status_nlp{
    display: block;
    padding:5px;
    background-color: #4b944b;
    color: #ffffff;
    margin: 5px;
    border-radius:10px;
    font-size:0.7rem;
    text-align: center;

}
.change-log-div{
    display: flex;
    flex-direction: row;
}
.change-log-wrapper{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding:30px;
    margin-bottom: 10px;
    border:1px solid #dee2e6;
    border-radius: 6px;
    font-weight: 700;
    color:#6c757d;
    height:58px;
    background-color: #f8f9fa;
}
.log_viewer {
    font-family: 'Fira Mono';
    width: 100%;
    height: 50vh;
    box-sizing: border-box;
    margin: auto;

}

.log_viewer header {
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    background-color: #555555;
    height: 45px;
    line-height: 45px;
    text-align: center;
    color: #DDD;
}

.log_viewer .consolebody {
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
    box-sizing: border-box;
    padding: 20px;
    height: 40vh;
    overflow: scroll;
    background-color: #000;
    color: #ffffff;
}

.log_viewer .consolebody p {
    line-height: 1;
}
.p-accordion-content{
    padding: 0!important;
}