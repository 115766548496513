.admin-wrapper{
    display: flex;
    flex-direction: column;
    gap:30px;
    padding: 40px;
    width: 400px;
    margin-left: 100px;
}
.admin-info-wrapper{
    display: flex;
    flex-direction: column;
    gap: 10px;
}
.p-inputtext.p-component.p-password-input{
    width: 100%;
}