.dashboard-page {
    width: 100%;
    display: grid;
    grid-template-columns: 20% 20% 20% 20% 20%;
    gap: 15px;
    padding: 50px;
    justify-content: center;
}

.dashboard-card-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.dashboard-card-left {}

.dashboard-card-right {}

.dashboard-card-title {
    font-weight: bold;
    font-size: 1.4rem;
    margin-bottom: 1rem;
}

.dashboard-card-value {
    font-weight: bold;
}

.dashboard-card-content i {
    padding: 10px;
    border-radius: 10px;
}

.grid {
    display: flex;
    flex-wrap: wrap;
    margin-right: -0.5rem;
    margin-left: -0.5rem;
    margin-top: -0.5rem;
}

.col {
    flex-grow: 1;
    flex-basis: 0;
}