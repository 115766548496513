.header{
    width: 100%;
    height: 100vh;
}
.masterDataSet{
    margin: 20px;
}
.masterTop{
    display: flex;
    flex-direction: column;
    gap: 25px;
    align-items: center;
    background-color: #F8F9FA;
    padding: 45px 25px 45px 25px;
    border:1px solid rgb(222, 226, 230)
}
.masterMid{
    display: flex;
    gap: 25px;
    align-items: center;
    justify-content: center;
    width:100%;
}
.clearButton{
    background: transparent;
    border: none;
    cursor: pointer;
    color: #1D4ED8;
}
.masterBot{
    margin-top: 20px;
    border: 1px solid rgb(222, 226, 230);
}
.master-dataset-dropdown{
    width: 20%;
}