.chat-history-viewer{
    width: 100%;
    height: 60%;
    display: flex;
    min-height: 100px;
}
.chv-wrapper{
    width: 100%;
    height: 60%;
    display:flex;
    flex-direction:column;
    justify-content: flex-start;
}
.chv-message{
    min-width:200px;
    min-height: 60px;
    display: flex;
    gap:10px;
    margin-bottom: 20px;
}
.chv-message.user{
    align-self:flex-start;
    display:flex;
    flex-direction: row;
    justify-content: flex-start;
}
.chv-message.bot{
    align-self:flex-end;
    display:flex;
    flex-direction: row-reverse;
    justify-content: flex-start;
}
.chv-body{
    display: flex;
    background-color: #1d2b36;
    color: white;
    font-weight: bold;
    box-sizing: border-box;
    padding:20px;
    border-radius: 10px;
    max-width: 300px;
}
.chv-avatar{
    width: 60px;
    height: 60px;
}
.chv-information-wrapper{
    display:flex;
    flex-direction:column;
    gap:10px;
    margin-bottom: 30px;
}
.title-span{
    font-weight: bold;
}
.chat-log-message-body a{
    color: cyan;
}