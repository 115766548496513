.react-flow__node-video {
    font-size: 12px;
    background: rgb(228, 241, 255);
    border: 2px solid rgb(17, 130, 242) !important;
    border-radius: 10px;
    text-align: center;
    /* box-shadow: 5px 5px 10px #88888860; */
    padding: 20px 10px !important;
    animation: node-animation 0.2s ease 0.1s 1 both;

}

.react-flow__node-video video {
    width: 200px;
    background-size: contain;
    object-fit: contain;
    margin-bottom: 20px;

}

.video-node-body {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    font-size: 16px;
    font-weight: bold;
}

.video-thumbnail {
    max-width: 200px;
    margin-bottom: 20px;
}

.video-thumbnail {
    width: 240px;
    height: 120px;
    background: black;
    background-size: cover;
    background-position: center;
}