.header{
    width: 100%;
    height: 80vh;
    display: flex;
    align-items: center;
    justify-content: center;
}
.cbu-card{
    width: 20%;
    height: 50vh;
    border:2px solid white;
    box-shadow: 0 20px 25px -5px rgb(0 0 0 / 0.1);
    border-radius: 30px;
    margin-left: 30px;
    background-color: white;
    justify-content: space-between;
    align-items: center;
    display: flex;
    flex-direction: column;
    padding-top:50px;
}

.cbu-card-plus{
    width: 20%;
    height: 50vh;
    border:2px white;
    box-shadow: 0 20px 25px -5px rgb(0 0 0 / 0.1);
    border-radius: 30px;
    margin-left: 30px;
    background-color: white;
    align-items: center;
    justify-content: space-evenly;
    display: flex;
    flex-direction: column;
    padding-top:50px;
}
.card-header{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.card-header-language-span{
    margin-bottom: 20px;
    font-weight: bold;
}
.active-language-div{
    display: flex;
    margin-top: 50px;
}
.active-language-span{
    font-weight: bold;
}
.admin-div{
    display: flex;
    margin-bottom: 20px;
}
.admin-span{
    font-weight: bold;
}
.dropdown-div{
    display: flex;
    justify-content: space-evenly;
}
.createNewCBU-div{
    display:flex;
    flex-direction: column;
}
.created-span{
    font-weight: bold;
}
.location-span{
    margin-top:30px
}
.create-dialog{
    width:40vw;
}
.dropdown-demo .p-dropdown {
    width: 14rem;
}
.cbu-head{
    display: flex;
    flex-direction: column;
    align-items: center;
}
.dropdown-demo .country-item-value img.flag {
    width: 17px;
}
