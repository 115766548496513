.widget-preview-wrapper{
    width: 400px;
    height:700px;
    border-radius: 16px;
    overflow: hidden;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}
.App{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow: hidden;
}
.widget-header{
    width: 100%;
    height: 56px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    user-select: none;
    position: relative;
    padding-left:10px;
    padding-right: 10px;
    background-color: rgb(71 147 254);
    color: rgb(255, 255, 255);
}
.greetings  {
    background-size: 100% 140%;
    background-position-y: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    flex: 1;
    padding-top: 56px;
    background-image: radial-gradient(circle,rgb(118,175,255) 0%,rgba(2,107,253,1) 100%);
    color: #252525;
    overflow: hidden;
}
.brand-logo{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 35%;
}
.greetings-card{
    user-select: none;
    width: 100%;
    height: 65%;
    background: #ffffff;
    border-top-left-radius: 40px;
    border-top-right-radius: 40px;
    box-shadow: #000 0 19px 38px,#000c 0 15px 12px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 30px;
    text-align: center;
}
.greetings-card svg{
    height: 60px;
    width: 50px;
    margin-bottom: 10px;
}
.accept-button{
    width: 100%;
    background-color: #336699;
    border-radius: 10px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 40px;
    color: #ffffff;
    font-weight: 700;
    font-size: 18px;
    opacity: 1;
    transition: opacity .2s;
    border: 0;
    outline: 0;
}