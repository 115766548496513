.edgebutton {
  width: 34px;
  height: 34px;
  background: rgb(255, 77, 77);
  border: 4px solid #fff;
  cursor: pointer;
  border-radius: 50%;
  font-size: 24px;
  line-height: 1;
  color: #fff;
  font-weight: bold;
}

.edgebutton:hover {
  box-shadow: 0 0 6px 2px rgba(0, 0, 0, 0.08);
}

.edgebutton-foreignobject div {
  background: transparent;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 40px;
}