.label-determination-card {
    width: 100%;
    margin-top: 50px;
}
.label-determination-body{
    gap:20px;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
}
.label-determination-title{
    font-size: 30px;
    width:100%;
    font-weight: bold;
    font-family: "Montserrat", sans-serif;
}
.label-determination-button-div{
    display: flex;
    justify-content: space-between;
    gap:20px;
    width: 100%;
    margin-top: 100px;

}
.label-determination-div{
    height:80vh;
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: space-between;
}
.flow-question-text{
    min-height: 44px !important;
}
.flow-question-text-wrapper{
    display: flex;
    align-items: center;
    justify-content: center;
    gap:5px
}
.new-question-wrapper{
    margin-left: 47px;
}
.description-wrapper{
    padding: 20px;
    text-align: center;
    font-family: "Montserrat", sans-serif;

}
.label-add-button{
    border-radius: 20px;
    width:20%
}
.add-button-wrapper{
    display:flex;
    justify-content: center;
}