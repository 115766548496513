.header{
    width: 100%;
    height: 60vh;
}
.users-input-div{
    width: 100%;
    display: flex;
    gap:20px;
    padding: 20px 30px;
    justify-content: space-evenly;
}
.users-filter-div{
    width: 100%;
    display: flex;
    flex-direction: column;
    border:1px solid #dee2e6;
    padding: 30px;
    background-color: #f8f9fa;
}
.users-bring-list-div{
    display: flex;
    width: 100%;
    justify-content: center;
    margin-top: 20px;
}
/* DataTableDemo.css */

.datatable-filter-demo .p-paginator .p-paginator-current {
    margin-left: auto;
}
.datatable-filter-demo .p-progressbar {
    height: 0.5rem;
    background-color: #d8dadc;
}
.datatable-filter-demo .p-progressbar .p-progressbar-value {
    background-color: #607d8b;
}
.datatable-filter-demo .p-datepicker {
    min-width: 25rem;
}
.datatable-filter-demo .p-datepicker td {
    font-weight: 400;
}
.datatable-filter-demo .p-datatable.p-datatable-customers .p-datatable-header {
    padding: 1rem;
    text-align: left;
    font-size: 1.5rem;
}
.datatable-filter-demo .p-datatable.p-datatable-customers .p-paginator {
    padding: 1rem;
}
.datatable-filter-demo .p-datatable.p-datatable-customers .p-datatable-thead > tr > th {
    text-align: left;
}
.datatable-filter-demo .p-datatable.p-datatable-customers .p-datatable-tbody > tr > td {
    cursor: auto;
}
.datatable-filter-demo .p-datatable.p-datatable-customers .p-dropdown-label:not(.p-placeholder) {
    text-transform: uppercase;
}
