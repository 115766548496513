.react-flow__node-text {
    font-size: 12px;
    background: rgb(228, 241, 255);
    border: 2px solid rgb(17, 130, 242);
    border-radius: 10px;
    text-align: center;
    /* box-shadow: 5px 5px 10px #88888860; */
    animation: node-animation 1s ease 0.1s 1 both;
    padding: 10px;
}

@keyframes node-animation {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

.question-node-body {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}

.react-flow__node-text textarea {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 16px;

    padding: 10px !important;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    border-radius: 10px;
    margin-bottom: 10px;
}

.node-label {
    font-weight: bold;
    font-size: 16px;
    text-align: left;
    margin-bottom: 5px;
    color: rgb(17, 130, 242)
}

.question-node-warning {
    display: flex;
    align-items: center;
    font-weight: 800;
    padding: 3px 10px;
    border-radius: 5px;
    transition: all 0.4s;
}

.node-remove-button {
    border-radius: 4px;
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgb(17, 130, 242);
    cursor: pointer;
}

.node-remove-button.disabled {
    background: rgb(179, 179, 179);
    opacity: 0.3;
    cursor: grab;
}

.node-remove-button.disabled .node-remove-button:hover {
    background: rgb(179, 179, 179) !important;
}

.node-remove-button:hover {
    background: #EF4444;
}

.node-remove-button .node-remove-button-icon {
    width: 20px;
    height: 20px;
    filter: invert(1);
}

.node-remove-button.disabled .node-remove-button-icon {
    width: 20px;
    height: 20px;
    filter: invert(0);
}

.node-header {
    display: flex;
    width: 250px;
    justify-content: space-between;
    margin-bottom: 10px;
}