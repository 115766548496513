.text-node-span{
    position: absolute;
    z-index: 9999;
    left:20px;
    padding:6px;
    background-color: white;
    border-radius: 10px;
    text-align: center;
    width: 300px;
    cursor: pointer;
    border:1px solid rgb(17, 130, 242)
}
.flow-viewer{
    position: relative;
}
.flow-translate-navigate {
    background-color: #DADADA;
    box-sizing: border-box;
    padding: 0px;
    margin-top: 20px;
    height: calc(100vh - 80px);
    top: 80px;
    position: absolute;
    overflow: auto;
    width: 20%;
    display: block;
    z-index: 9999999;
}

.flow-translate-navigate > div {
    width: 95%;
    margin-left: 2.5%;
    margin-top: 5px;
}
.flow-translate-navigate span {
    width: 100%;
    margin: 0px;
    padding: 0px;
    box-sizing: border-box;
    position: static;
    border-radius: 0px;
    text-align: left;
    font-size: 9pt;
    padding: 10px !important;
    text-indent:10px !important;
    cursor: pointer;

}

.flow-translate-navigate > div > span::before {
    content: "";
    display: block;
    float: left;
    width: 15px;
    height: 15px;
    background-color: #f00;
    border-radius: 255px;
}

.flow-translate-navigate > div > span.done::before {
    background-color: #0f0;
}

flow-translate-navigate div {
    width: 100%;
    padding: 0px;
    margin: 0px;
    box-sizing: border-box;
    position: static !important;
    margin-bottom: 0px;
    padding: 5px;
    background-color: transparent !important;
    margin-bottom: 0px !important;
    padding-bottom: 0px !important;

}
/* .react-flow__edge-path {
    transition: none !important;
  }
  
  .react-flow__edge-path path {
    transition: none !important;
  } */
