.react-flow__node-option {
    font-size: 12px;
    background: rgb(209, 246, 196);
    border: 2px solid rgb(17, 242, 84);
    border-radius: 10px;
    text-align: center;
    /* box-shadow: 5px 5px 10px #88888860; */
    padding: 10px 10px !important;
    animation: node-animation 0.2s ease 0.1s 1 both;
    min-width: 262px;
}

.option-node-body {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    font-size: 16px;
    font-weight: bold;
}

.option-dropdown-panel {
    max-width: 270px !important;
}