.radiobutton-wrapper{
    display: flex;
    height: 30%;
    gap:100px
}
.radio-div-wrapper{
    display: flex;
    gap:30px;
}
.radio-buttons{
    margin-left: 10px;
}
.answer-wrapper{
    display: flex;
    margin-bottom: 10px;
    margin-top: 10px;
}
.polls-wrapper{
    display: flex;
    flex-direction: column;
    gap:30px;
    padding: 40px;
    margin-left: 200px;
}