
.under-page{
    width: 100%;
    justify-content: center;
    display: flex;
    height: 100%;
}
.flow-wrapper{
    padding: 10px 40px 40px 40px;
    display: flex;
    flex-direction: column;
    flex:1
}
.add-flow-main{
    height: 100vh;
    display: flex;
    flex-direction: column;
    overflow: hidden;
}
