.header{
    width: 100%;
    height: 100vh;
}
.tabview-wrapper{
    width: 100%;
}
.appearance-wrapper{
    position:relative;
    width:70%;
    padding:10px;
    border:2px solid #EBEBEB;
    border-radius:20px;
    box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;
}
.column-wrapper{
    display: flex;
    flex-direction: column;
    width:100%;
    gap:30px;
    margin-top: 20px;
}
.color-column-wrapper{
    display: flex;
    flex-direction: column;
}
.color-wrapper{
    display: flex;
}
.API-wrapper{
    width: 70%;
    height: 50vh;
    display: flex;
    flex-direction: column;
    gap:30px;
    padding: 40px;
    border:2px solid #EBEBEB;
    border-radius:20px;
    box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;
}
.p-colorpicker input{
    width:60px;
    height:45px;
    margin-right:5px
}
.color-picker-input{
    height:45px;
}
.widget-container{
    display: flex;
    flex:1;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
}
.input-wrapper{
    display: flex;
    flex-direction: column;
    width: 30%;
}
.input-wrapper-chat{
    display: flex;
    flex-direction: column;
    width: 90%;
}
.settings-button-wrapper{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-left: 20px;
    padding-top:10px;
}
.settings-loader{
    width: 100%;
    display: flex;
    justify-content: center;
    height: 80vh;
    align-items: center;
}
.settings-default-wrapper{
    position: absolute;
    bottom: 30px;
    left: 30px;
}
.upload-wrapper{
    display: flex;
    justify-content: center;
    width: 100%;
    height: 100%;
    gap: 20px;
    align-items: center;
    background-color: #f8f9fa;
    border:1px #D5D9E0 solid;
    border-radius: 20px;
}
.upload-div{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.chats-uploader-section{
    display: flex;
    flex-direction: row;
    gap: 20px;
}
@media (max-width: 1302px) {
    .appearance-wrapper {
        width: 60%;
    }
    .color-picker-wrapper input{
        width: 100% !important;
    }
    .chats-uploader-section{
        flex-direction: column;
    }
}