.question-selection-wrapper{
    display: flex;
    flex-direction: column;
    padding: 8px;
    margin-top: 2px;
    width: 100%;
    align-items: flex-start;
    background-color: #E3DFDF;
}
.question-selection-wrapper:hover{
    background-color: gray;
}
.translation-button-wrapper{
    width: 100%;
    gap:10px;
    display: flex;
   justify-content: space-between;
    margin-top: 10px;
}
.translation-question-span{
    font-weight: bold;
    margin-bottom: 10px;
}
.node-translation-label {
    font-weight: bold;
    font-size: 14px;
    text-align: left;
    margin-bottom: 5px;
    color: rgb(17, 130, 242)
}
.question-translation-textarea{
    -webkit-user-select: auto;
    -moz-user-select: auto;
    -ms-user-select: auto;
    user-select: auto;
}
