.masterEdit{
    display: flex;
    flex-direction: column;
    gap: 15px;
}
.masterDataCase{
    display: flex;
    flex-direction: column;
    gap: 10px;
}
.masterDataBottom{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    margin-top: 15px;
}