.caseLabels{
    margin: 20px;
}
.caseLabelsFilter{
    display: flex;
    gap: 25px;
    justify-content: center;
    align-items: center;
    background-color: #F8F9FA;
    padding: 45px 25px 45px 25px;
    border:1px solid rgb(222, 226, 230)
}
.caseLabelsTable{
    margin-top: 50px;
    border:1px solid rgb(222, 226, 230)
}
.information-div{
    margin:10px 0 10px 0;
    width: 100%;
    background-color: rgba(255, 159, 64, 1);
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    font-weight: bold;
}
.caseLabelChart{
    margin: 20px;
}
.caseChange-wrapper{
    display: flex;
    gap:10px;
}

.caseChange-delete-icon-wrapper {
    border: 1px solid #F44336;
    padding: 5px;
    border-radius: 14px;
    padding-right: 8px;
}
.caseChange-create-icon-wrapper,
.caseChange-delete-icon-wrapper,
.caseChange-update-icon-wrapper{
    border: 1px solid #F44336;
    padding: 5px;
    border-radius: 14px;
    padding-right: 8px;
    background-color: white;
}
.caseChange-create-icon-wrapper span,
.caseChange-delete-icon-wrapper span,
.caseChange-update-icon-wrapper span{
    color: #F44336;
    line-height: 20px;
    padding-left: 5px;
    line-height: 20px;
    font-weight: 600;
    font-size: 10pt;
}

.caseChange-create-icon-wrapper i,
.caseChange-delete-icon-wrapper i,
.caseChange-update-icon-wrapper i{
    background-color: #F44336;
    color: #fff;
    font-size: 9pt;
    border-radius: 25px;
    line-height: 20px;
    width: 20px;
    box-sizing: border-box;
    height: 20px;
    text-align: center;
}
.caseChange-update-icon-wrapper{
    border: 1px solid #03A9F4;
}
.caseChange-update-icon-wrapper span{
    color: #03A9F4;
}
.caseChange-update-icon-wrapper i{
    background-color: #03A9F4;
}
.caseChange-create-icon-wrapper{
    border: 1px solid #2ABE48;
}
.caseChange-create-icon-wrapper span{
    color: #2ABE48;
}
.caseChange-create-icon-wrapper i{
    background-color: #2ABE48;
}