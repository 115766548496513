.caseSentences{
    margin: 20px;
}
.caseSentencesFilter{
    display: flex;
    gap: 25px;
    justify-content: center;
    align-items: center;
    background-color: #F8F9FA;
    padding: 45px 25px 45px 25px;
    border:1px solid rgb(222, 226, 230)
}
.caseSentenceTable{
    margin-top: 50px;
    border:1px solid rgb(222, 226, 230)
}
.caseSentencesTop{
    margin: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid black;
    padding-bottom: 10px;
}
.caseFlows{
    display: flex;
    flex-direction: column;
    gap: 10px;
}
.caseTags{
    display: flex;
    align-items: center;
    gap: 10px;
}
.caseDescription{
    margin: 20px;
    display: flex;
    flex-direction: column;
    gap: 10px;
}
.dialog{
    display: flex;
    flex-direction: column;
    gap: 15px;
}
.dialogButton{
   display: flex;
   justify-content: center;
   align-items: center;
   gap: 10px;
   margin-top: 15px;
}
.newSentence{
    display: flex;
    flex-direction: column;
    gap: 15px;
}
.cases-label-dropdown{
    width: 30%;
}
.clear-button-case-label{
    display: flex;
    margin-left: 10px;
    align-items: center;
    cursor: pointer;
    color: #1d4ed8;

}