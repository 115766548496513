.widget-chat-wrapper{
    width: 400px;
    height:700px;
    border-radius: 16px;
    overflow: hidden;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}
.App-chat{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow: hidden;
}
.widget-header{
    width: 100%;
    height: 56px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    user-select: none;
    padding-left:10px;
    padding-right: 10px;
    background-color: rgb(71 147 254);
    color: rgb(255, 255, 255);
}
.about-page{
    display: flex;
    padding-top: 10px;
    height: 100%;
    animation: about-page-reveal .3s 1;
}
.about-container{
    color: #fff;
    background: rgb(83,155,255);
    width: 100%;
    margin: 30px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: 10px;
    box-shadow: #32325d40 0 13px 27px -5px,#0000004d 0 8px 16px -8px;
}
.close-about-page{
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin-bottom: 1em;
}
.app-title{
    font-size: 1.2rem;
    font-weight: 700;
}
.app-description{
    font-size: .9rem;
    text-align: start;
    margin-top: 20px;
    white-space: pre-line;
}

.conversation-container{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    height: calc(100% - 56px);
    background-color: rgb(255, 255, 255);
}
.chat-bubbles{
    display: flex;
    flex-direction: column;
    flex: 1;
    padding: 8px;
    overflow-anchor: none;
}
.chat-bubble{
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;
    animation: slide-in .6s ease-in-out 1 both;
    font-family: Noto Sans,sans-serif;
}
.chat-bubble-content{
    display: flex;
    gap: 10px;
    width: 100%;
}
.avatar{
    height: 100%;
    display: flex;
    justify-content: center;
}
.avatar-image{
    width: 50px;
    height: 50px;
    border-radius: 50%;
}
.bubble-body{
    width: 70%;
    height: 100%;
    padding: 0 0 5px;
    display: flex;
}
.message-content.left{
    background: rgb(255, 255, 255);
    color: rgb(17, 17, 17);
}
.message-content.right{
    background: rgb(17, 17, 17);
    color: white;
}
.bubble-body.rounded-top .message-content.right{
    border-radius: 20px;
}
.bubble-body.rounded-top .message-content.left{
    border-radius: 20px;
}
.bubble-body.rounded-bottom .message-content.right{
    border-radius: 20px;
}
.bubble-body.rounded-bottom .message-content.left{
    border-radius: 20px;
}
.flex-container{
    display: flex;
    padding-top: 56px;
    animation: question-reveal .3s 1;
    position: absolute;
    z-index: 99;
    width: 80%;
    height: auto;
    left: 10%;
    top: 10%;
    overflow: auto;
}
.reset-container{
    color: #000;
    background: white;
    width: 100%;
    margin: 24px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: 16px;
    box-shadow: #32325d40 0 13px 27px -5px,#0000004d 0 8px 16px -8px;
}
.reset-container .app-description{
    font-size: 18px;
    text-align: center;
    margin-top: 20px;
}
.col{
    float: left;
    width: 50%;
    padding: 10px;
}
.reset-container button{
    border-radius: 5px;
    background: rgba(2,107,253,1);
    cursor: pointer;
    width: 100%;
    text-align: center;
    transition: transform .2s;
    font-weight: 700;
    border: 1px solid #dcdcdc;
    color: #fff;
    animation: slide-in .3s ease-in-out 1 both;
    opacity: .8!important;
    font-size: 14px;
    padding: 12px 20px;
    text-decoration: none;
    text-shadow: 0px 0px 0px #2f6627;
}
.header-popover-menu{
    overflow: hidden;
    background: #ffffff;
    flex-direction: column;
    z-index: 2;
    animation: popover-menu-reveal .3s ease .1s 1 both;
    width: 250px;
    position: absolute;
    top: 40px;
    left: 30px;
    border-radius: 0 10px 10px/0px 10px 10px;
    box-shadow: #3c40434d 0 1px 2px,#3c404326 0 1px 3px 1px;
}
.header-popover-menu-item svg{
    width: 16px;
    margin-right: 10px;
}
.header-popover-menu-item{
    height: 50px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: 14px;
    padding-left: 20px;
    transition: padding-left .1s;
    color: #000;
}
.message-text{
    font-size: .8rem;
    padding: 4px;
    word-break: break-word;
    font-weight: 400;
    hyphens: auto;

}
.message-time{
    font-size: .7rem;
    width: 100%;
    margin-top: 3px;
    font-weight: 200;
    text-align: end;
    color: #000 !important;
}
.message-content{
    padding: 10px;
    border-radius: 4px;
    transition: border-radius 1s;
    max-width: 90%;
    border: 1px solid #dcdcdc;
}
.options-wrapper{
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin-bottom: 20px;
    padding: 10px 20px;
    flex-wrap: wrap;
    gap: 10px;
}
.option{
    padding: 10px;
    border-radius: 12px;
    background: rgba(2, 107, 253, 1);
    cursor: pointer;
    min-width: 120px;
    text-align: center;
    transition: transform .2s;
    font-weight: 700;
    border: 1px solid #dcdcdc;
    color: white;
    animation: slide-in .3s ease-in-out 1 both;
    opacity: .8!important;
}
.chat-input-wrapper{
    width: 100%;
    display: flex;
    border-top: 1px solid #dadada;
    min-height: 56px;
}
.text-input{
    appearance: none;
    resize: none;
    border: none;
    line-height: 1.5em;
    min-width: 0;
    width: 100%;
    -webkit-box-flex: 1;
    flex-grow: 1;
    font-size: 14px;
    max-width: 100%;
    font-family: inherit;
    padding: 1.25em 12em 1.25em 0.2em;
    overflow-y: auto;
    margin-top: 0;
    background-color: white;
    color: rgb(17, 17, 17);
    height: 56px!important;
}
.send-button{
    border: none;
    width: 56px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff;
}
.start-over-button{
    padding: 15px;
    margin:10px;
    border-radius: 12px;
    background: rgba(2,107,253,1);
    cursor: pointer;
    min-width: 120px;
    text-align: center;
    transition: transform .2s;
    font-weight: 700;
    border: 1px solid #dcdcdc;
    color: #fff;
    animation: slide-in .3s ease-in-out 1 both;
    opacity: .8!important;
}
